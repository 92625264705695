import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IResponse } from '@shared/interfaces/iresponse';
import { environment } from 'environments/environment';
import { Observable, Subject, map } from 'rxjs';

interface DashboardCount {
  Module: string;
  Count: number;
  Ref:string;
}

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  http = inject(HttpClient);
  searchKeyword:string ="";
  constructor() { }

  FetchOrganizationDashbordCount(payload: { OrganisationId: string, OrganizationType: string }) {
    return <Observable<DashboardCount[]>>this.http.post(`${environment.ctUrl}Organization/FetchOrganizationDashboardCount`, payload).pipe(map((
      res: IResponse
    ) => res.Data));
  }
}
